import React from "react"
import Layout from "components/Layout/Layout"
import MaxContainer from "components/shared/MaxContainer"
import ImagesWithText from "components/shared/ImagesWithText"
import SEO from "utils/Seo"

import image1 from "images/home/3.jpg"
import image2 from "images/home/8.jpg"


const DesignProcessPage = () => (
    <Layout>
        <SEO title="Design Process | Patio Contractors | Landscape Gardener Cornwall" description="Our design process is a quick & easy way to achieve the landscape of your dreams. We go through all the steps involved here. Please contact us for friendly advice & a free initial consultation to get the ball rolling!" />
        <MaxContainer>
            <ImagesWithText
                image1={{ src: image1, alt: "landscape gardening" }}
                image2={{ src: image2, alt: "landscape gardening" }}
            >
                <h1>The design and build process</h1>
                <ul>
                    <li><p>Firstly we will discuss your needs and budget to help develop a 'Design Brief' which is a written framework of your needs, likes and dislikes.</p></li>
                    <li><p>We will conduct a site survey.</p></li>
                    <li><p>We will then develop an individual design based on creative solutions to the problems posed in the design brief.</p></li>
                    <li><p>You will then be presented with the plan and a quote for the proposed works for your approval, along with samples of proposed hard landscape materials.</p></li>
                    <li><p>On approval of the plan we will discuss the implementation of construction works with as little disruption to you as is possible.</p></li>
                </ul>
                <p>Please contact Owen for friendly advice and a free initial consultation to get the most from your outside space.</p>
            </ImagesWithText>
        </MaxContainer>
    </Layout>
)

export default DesignProcessPage
